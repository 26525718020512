import React, { useRef } from 'react'

import Slider from 'react-slick'

const AIPoweredSpeakingScenarios = (props) => {
  let sliderRef = useRef(null)

  const pause = () => {
    sliderRef.slickPause()
  }

  function NextArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{ ...style }}
        onClick={() => {
          onClick()
          pause()
        }}
      />
    )
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{ ...style }}
        onClick={() => {
          onClick()
          pause()
        }}
      />
    )
  }

  let settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    arrows: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnDotsHover: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false
        }
      }
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    onSwipe: () => {
      pause()
    }
  }

  const { carouselImages, openLightboxWithImageIndex } = props

  const carouselAreaClick = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'gtm-interactive-element-home-page-marketing-image-carousel-real-as-virtual'
    })
  }

  return (
    <div className="row">
      <div className="col-md-6 order-md-1">
        <h3 className="show-on-mobile">AI-Powered Speaking Scenarios</h3>
        <div className="slider-wrap gtm-feature-area" onClick={carouselAreaClick}>
          <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
            {carouselImages.map((img, index) => (
              <div key={`realism-${index}`} onClick={() => openLightboxWithImageIndex(index, 0)}>
                <img src={img} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="col-md-6 order-md-2">
        <h3 className="hide-on-mobile">AI-Powered Speaking Scenarios</h3>
        <h4>
          Ovation‘s simulated audience members can engage in intelligent dialogues, powered by ChatGPT.
          <br />
          <br />
          <b>Speech + Q&A: </b>Deliver your presentation, then take questions from your audience.
          <br />
          <br />
          <b>Conversation: </b>Choose any topic and discuss it with simulated participants.
          <br />
          <br />
          <b>Interview: </b>Prepare for any job interview in any industry. Alternatively, be the interviewer and
          evaluate AI applicants.
        </h4>
      </div>
    </div>
  )
}

export default AIPoweredSpeakingScenarios
