import React from 'react'
import { Link } from 'gatsby'

import './WithoutHeadset.scss'

const OvationForDesktop = 'https://d1syj4d8txnu77.cloudfront.net/OvationDesktop/OvationForDesktop.png'

const WithoutHeadset = () => (
  <div className="without-headset">
    <div className="wrapper">
      <div className="row position-relative">
        <div className="col-md-6">
          <h2>No VR headset? No problem!</h2>
          <h4>
            Ovation is available for Windows-based computers with our Monitor Application. If you prefer not to use VR,
            or want to try Ovation before investing in a headset, this is a great option.
          </h4>
        </div>
        <div className="col-md-6 text-center">
          <img className="img" src={OvationForDesktop} />
        </div>
        <Link className="signup-button action-button" to="/get-started?license=monitor&subscription=individual">
          Get for Windows
        </Link>
      </div>
    </div>
  </div>
)

export default WithoutHeadset
