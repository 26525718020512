import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import './EmailList.scss'

const brevoSignUpUrl =
  'https://4f9eaeef.sibforms.com/serve/MUIFAAL4H4K4q3mwrR_VFfJFx4zdvBIKyIpl__QoUpHaJ78u6hLsKJPJotOZ_QwNme4vdafPndR0x9y1S9JZwzX7m5q5XjBlCtd0jtVrUMMOzhfrdjBnEbvaIDG6xPB-w9UE0BRa4TGa1UYE02lv5oWWSOOKvJ5tI4kKm-ju8L0_AH39yM0DOOWESJGupQWZ1KgOiLQJW4rPKAb8'

const EmailList = () => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const [width, setWidth] = useState(0)

  const openModal = () => {
    setWidth(window.innerWidth)
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions)

    return () => {
      window.removeEventListener('resize', updateWindowDimensions)
    }
  }, [])

  return (
    <div className="email-list">
      <div className="wrapper">
        <div style={{ maxWidth: '750px', margin: '0 auto' }}>
          <h2>Sign up to receive the latest news from Ovation, including software updates, tips, events, and more.</h2>
        </div>
        <button className="email-list-submit" onClick={() => openModal()}>
          Subscribe
        </button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        onRequestClose={closeModal}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: 0
          },
          overlay: {
            zIndex: 100
          }
        }}
      >
        <iframe
          width={width > 767 ? 540 : 360}
          height={510}
          src={brevoSignUpUrl}
          frameBorder="0"
          scrolling="auto"
        ></iframe>
        <div style={{ textAlign: 'right', marginBottom: 20, marginRight: 20 }}>
          <button onClick={() => closeModal()} className="modal-close-button">
            Close
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default EmailList
