import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import './PurposeHero.scss'

const QuestImg = 'https://d1syj4d8txnu77.cloudfront.net/SubHero/Quest.png'

export default () => (
  <div className="purpose-hero">
    <div className="wrapper">
      <div className="row">
        <div className="col-md-4">
          <StaticImage className="img" src={QuestImg} alt="Quest" imgStyle={{ objectFit: 'contain' }} />
        </div>
        <div className="col-md-8">
          <h2>Why VR + Public Speaking?</h2>
          <h4>
            The fusion of virtual reality and public speaking represents a groundbreaking advancement. Now, individuals
            can confront their speaking anxieties by repeated exposure to virtual simulations of the real-world
            scenarios they dread. <br /> <br />
            And as their fears are reprogrammed, their skills are also honed, transforming them into impactful speakers
            ready to command any stage.
          </h4>
        </div>
      </div>
    </div>
  </div>
)
